import Image from 'next/image';
import { skeleton } from '../../skeleton';
import YStack from '../YStack/YStack';

type Props = {
  size?: number;
  loading?: boolean;
  username: string;
};

if (!process.env.NEXT_PUBLIC_DYNAMIC_API_ENDPOINT) {
  throw Error('NEXT_PUBLIC_DYNAMIC_API_ENDPOINT is missing');
}

const AVATAR_API_ENDPOINT = `${process.env.NEXT_PUBLIC_DYNAMIC_API_ENDPOINT}/api/avatar`;

const Avatar = ({ size = 35, username, loading }: Props) => {
  if (loading) {
    return <YStack width={size} height={size} className={skeleton.className('circle', true)} borderRadius="50%" />;
  }

  return (
    <Image
      src={`${AVATAR_API_ENDPOINT}/${encodeURIComponent(username)}?size=${size * 3}`}
      width={size}
      height={size}
      style={{ backgroundColor: 'var(--border)', borderRadius: '50%' }}
      alt="Round gradient between two colors"
    />
  );
};

export default Avatar;
