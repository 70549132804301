import { YStack, XStack, Image, Text, Button } from '@holo/ui';
import { Link } from '@holo/link';
import type { CartItem } from '@holo/gql';

const MAX_ITEMS = 4;

type Props = {
  store: {
    logo?: string;
    name: string;
    slug: string;
  };
  items: CartItem[];
};

const ActiveCartsItem = ({ store, items }: Props) => {
  const firstItems = items?.slice(0, MAX_ITEMS);
  const moreItems = items && items.length - MAX_ITEMS;

  return (
    <YStack mb="$6" borderBottomColor="$border" borderWidth={1} borderColor="$border" borderRadius={16} padding={16}>
      <YStack mb="$8">
        <XStack jc="space-between">
          <XStack ai="center" flex={1}>
            <Link href={`/${store.slug}`}>
              <Image src={store.logo} width={30} height={30} borderRadius={30} alt={store.name} />
            </Link>
            <Link href={`/${store.slug}`}>
              <Text fontWeight="$medium" ml="$2">
                {store.name}
              </Text>
            </Link>
          </XStack>

          <XStack>
            {firstItems?.map((item) => {
              return (
                <YStack ml="$2" key={item.id}>
                  <Image src={item.image} alt="" width={30} height={30} borderRadius={8} />
                </YStack>
              );
            })}

            {moreItems > 0 && (
              <YStack
                ml="$2"
                width={30}
                height={30}
                borderRadius={8}
                backgroundColor="$contrast"
                ai="center"
                jc="center"
              >
                <Text color="$background" fontSize="$10" fontWeight="$medium">
                  {`+${moreItems}`}
                </Text>
              </YStack>
            )}
          </XStack>
        </XStack>
      </YStack>

      <Link href={`/${store.slug}`}>
        <Button width="100%" variant="tertiary">
          Continue shopping
        </Button>
      </Link>
    </YStack>
  );
};

export default ActiveCartsItem;
